import React,{useContext} from 'react'
import {Route,Redirect} from 'react-router-dom'
import { UserContext } from '../utils/UserContext'


const ProtectedRoute = ({component:Component,...rest}) => {
    const {isLoggedIn}=useContext(UserContext)

    return (
     <Route {...rest} render={(props)=>{

        if(isLoggedIn()===true){
            return <Component {...props}/>
        }else{
            return <Redirect to={{pathname:'/login',state:{from:props.location}}}/>
        }
     }

     }/>
    );
}

export default ProtectedRoute
