import React from 'react'
import {NavLink} from 'react-router-dom'
import store from  'store'
import {API_BASE_URL} from '../utils/Constants'

const Header = ({children,isSignedIn}) => {

    const logout=(e)=>{
        e.preventDefault()
        store.clearAll()
        window.location.pathname="/"


    }
    return (
        <header className="header">
            <h3 style={{display:'inline'}}>{children}</h3>
            <nav className="nav">
                <NavLink exact to="/" activeClassName="menu_active">Orders</NavLink>
                <NavLink exact to="/archive" activeClassName="menu_active">Archive</NavLink>
                
                {isSignedIn?
                <>
                    <a style={{float:'right'}} href="#logout" onClick={(e)=>{logout(e)}}>Logout</a>
                    <a style={{float:'right'}} href={API_BASE_URL+"/update?next="+encodeURIComponent(window.location.href)}>Update</a>
                </>
                
                
                :null}
                

                
            
            </nav>
        </header>
    )
}

export default Header
