import React from 'react'

const Footer = () => {
    return (
       <footer className="footer">
        <p>
            Anvil Ltd. &copy; 2021
        </p>
       </footer>
       
    )
}

export default Footer
