import {useState} from 'react'
import Header from './components/Header'
import Footer from './components/Footer'
import {BrowserRouter as Router,Route} from 'react-router-dom'
import Home from './pages/Home'
import ProtectedRoute from './components/ProtectedRoute'
import Archive from './pages/Archive'
import {UserContext} from './utils/UserContext'
import store from 'store'
import Login from './pages/Login'


function App() {

  const [isSignedIn,setIsSignedIn]=useState(false)

const username=()=>{
  return store.get('username')
}

const userToken=()=>{
  return store.get('token')
}

const isLoggedIn=()=>{
  setIsSignedIn(store.get('isAuth',false))
  return store.get('isAuth',false)
  
}



  return (
    <Router>
    <UserContext.Provider value={{username,userToken,isLoggedIn}}>
      <Route>
        <Header isSignedIn={isSignedIn}>AnvilTD Belt Orders</Header>
      </Route>
      <div className="container card">
            <ProtectedRoute exact path="/" component={Home}/>
            <ProtectedRoute path="/archive"  component={Archive}/>
            <Route path="/login" component={Login}/>
      </div>
      </UserContext.Provider>
      <Footer/>
    </Router>
  );
}

export default App;
