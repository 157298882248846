import React,{useContext} from 'react'
import {Link} from 'react-router-dom'
import { useDrop } from 'react-dnd'
import Order from '../components/Order'
import {ItemTypes} from '../utils/Items'
import {BoardContext} from '../pages/Home'
import {OrderStatus} from '../utils/Constants'



const Board = ({title,orders,status}) => {

    const {setOrderStatus}=useContext(BoardContext);

    const [{isOver},drop]=useDrop({
        accept:ItemTypes.ORDER,
        drop:(item,monitor)=>setOrderStatus(item.id,status),
        collect:monitor=>({
            isOver: !!monitor.isOver(),
        }),
    });




    return (
        <div ref={drop} className={isOver?"board board__is_over":"board"}>
                        <div><h3 className="board__title">{title} ({orders.filter(o=>o.status===status).length})</h3></div>
                        <div className="board__orders">
                        {status===OrderStatus.ORDER_STATUS_ARCHIVE?
                        <div className="centered">
                            <Link className="btn-archive" to="/archive">See archived items</Link>
                        </div>
                        : 
                            orders.sort((a,b)=>b.order_number-a.order_number).filter(o=>o.status===status).map(order=>{
                                return(
                                    <Order key={order.order_id} order={order}/>
                                )
                            })
                        
                        }
                        </div>
                    </div>
    )
}

export default Board
