import React, { useState, useContext } from "react";
import { ItemTypes } from "../utils/Items";
import { useDrag } from "react-dnd";
import { API_BASE_URL } from "../utils/Constants";
import { BoardContext } from "../pages/Home";
import { FaStickyNote, FaRegStickyNote } from "react-icons/fa";
import Modal from "react-modal";
import {IoMdCloseCircle} from 'react-icons/io'

const Order = ({ order }) => {
    Modal.setAppElement('#root');
    const { setOrderHighlighted, setOrderSelected, updateOrderNote } =
        useContext(BoardContext);
    const [highlight, setHighlight] = useState(false);
    const [selected, setSelected] = useState(false);
    const [note, setNote] = useState(order.note);
    const [modalIsOpen, setModalOpen] = useState(false);

    const openModal = () => {
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);

    };


    const saveNote=(e)=>
    {
        e.preventDefault();
        updateOrderNote(order.order_id,note)
        closeModal()
    }


    const deleteNote=(e)=>{
        e.preventDefault();
        setNote("")
        updateOrderNote(order.order_id,"")
        closeModal()

    }

    const onHighlight = (e) => {
        const newHighlight = !highlight;
        e.preventDefault();
        setHighlight(newHighlight);
        setOrderHighlighted(order.order_id, newHighlight);
    };

    const onSelected = (e) => {
        const isChecked = e.currentTarget.checked;
        setSelected(isChecked);
        setOrderSelected(order.order_id, isChecked);
    };

    const [{ isDragging }, drag] = useDrag({
        type: ItemTypes.ORDER,
        item: {
            id: order.order_id,
            highlight: highlight,
        },
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        }),
    });

    const order_url =
        "https://undr-australia.myshopify.com/admin/orders/" +
        order.order_id +
        "?orderListBeta=true";

    return (
        <div
            ref={drag}
            onDoubleClick={(e) => onHighlight(e)}
            className={`
            ${isDragging ? "order order__dragging" : "order"}
            ${highlight ? "order__highlight" : ""}
            `}
        >
            <div>
                {/* modal */}
                <Modal className="note"
                    isOpen={modalIsOpen}
                    // onAfterOpen={afterOpenModal}
                    onRequestClose={closeModal}
                    contentLabel="Example Modal"
                >
                    <h3 className="note__title">Note</h3>
                    <div>
                        <button className="note__close" onClick={closeModal}><IoMdCloseCircle/></button>
                    </div>

                    <div className="note__body">

                    <form onSubmit={e=>{e.preventDefault()}}>
                        <textarea onChange={e=>setNote(e.target.value)} value={note} placeholder="Write a note..." name="note" id="note"/>
                        <div style={{float:'right'}}>
                            <button  onClick={e=>deleteNote(e)} className="note__btn note__btn__delete">Delete</button>
                            <button onClick={e=>saveNote(e)} className="note__btn note__btn__save">Save</button>
                        </div>
                    </form>

                    </div>
                </Modal>

                {/* modal close */}

                <form onSubmit={(e) => e.preventDefault()}>
                    <input
                        onChange={(e) => onSelected(e)}
                        type="checkbox"
                        name="order_selected"
                        id="order_selected"
                        checked={selected}
                    />
                </form>
            </div>

            <div>
                <div className="order__title">
                    {/* Order Id: <a target="_blank" rel="noreferrer" href={order_url}>{order.order_id}</a> <br /> */}
                    Order No:{" "}
                    <a target="_blank" rel="noreferrer" href={order_url}>
                        {order.order_number}
                    </a>{" "}
                    <br />
                    {/* Price: ${order.total_price} */}
                </div>

                <div className="order__customer">
                    {/* <div className="order__email">
                {order.customer_email}
                </div> */}

                    <div className="order__customer_name">{order.customer_name}</div>
                </div>

                <div className="order__pdf">
                    <a
                        target="_blank"
                        rel="noreferrer"
                        href={API_BASE_URL + "/pdf/order-" + order.order_number}
                    >
                        DOWNLOAD PDF
                    </a>
                </div>
            </div>

            <div>
            <h2 className="note__icon ">
                {note && note!== "" ? (
                    <div className="tooltip">
                        <FaStickyNote onClick={openModal} />
                        <span class="tooltiptext">{note}</span>
                        </div>
                ) : (
                   
                        
                            <FaRegStickyNote onClick={openModal} />
                       
                   
                )}

            </h2>
                
            </div>
        </div>
    );
};

export default Order;
