import React,{useState} from 'react'
import axios from 'axios'
import store from 'store'
import {API_BASE_URL} from '../utils/Constants'


const Login = (props) => {

    const [username_input, setUsernameInput] = useState('')
    const [password_input, setPasswordInput] = useState('')
    const [errMessage,setErrMessage]=useState('')


    const handleUsername=(e)=>{
        setErrMessage('')
        setUsernameInput(e.target.value)
    }

    const handlePassword=(e)=>{
        setErrMessage('')
        setPasswordInput(e.target.value)
    }


    const actionLogin=(e)=>{
        e.preventDefault()

        if (!username_input){
            setErrMessage("Username can't be blank")
            return
        }

        if(!password_input){
            setErrMessage("Password can't be blank")
            return
        }


        const params = new URLSearchParams();
        params.append('username', username_input);
        params.append('password',password_input);

        const loginUrl=API_BASE_URL+"/login"

        axios.post(loginUrl,params)
        .then(resp=>{
            console.log(resp)

            if(resp.data.status==="success"){

                var _username=resp.data.username;
                var _token=resp.data.token;

                store.set('username',_username)
                store.set('token',_token)
                store.set('isAuth',true)

                props.history.push('/')

            }else if(resp.data.status==="invalid_credentials"){
               
                setUsernameInput("")
                setPasswordInput("")
                setErrMessage("Invalid Credentials")

            }
        })
        .catch(err=>{
            console.log(err)
        })

    }


    return (
        <div style={{overflowX:'hidden'}}>
            <h3 style={{textAlign:'center'}}>Login</h3>
            <form onSubmit={(e)=>actionLogin(e)}>
                <label htmlFor="username">Username</label>
                <input required type="text" name="username" id="username" value={username_input} onChange={(e)=>handleUsername(e)} />
                <br />
                <label htmlFor="password">Password</label>
                <input required type="password" name="password" id="password" value={password_input} onChange={(e)=>handlePassword(e)} />
                
                <br />
                <span className="error-message">{errMessage}</span><br />

                <button type="submit">Login</button>
            </form>
        </div>
    )
}

export default Login
