export const OrderStatus={
    ORDER_STATUS_NEW_ORDER:"new_order",
    ORDER_STATUS_CUSTOM_WORK:"custom_work",
    ORDER_STATUS_RECEIVED_AND_PROCESSED:"processed",
    ORDER_STATUS_IN_PRODUCTION:"in_production",
    ORDER_STATUS_ALMOST_COMPLETE:"almost_complete",
    ORDER_STATUS_FINAL_STAGES:"final_stages",
    ORDER_STATUS_COMPLETE:"complete",
    ORDER_STATUS_ARCHIVE:"archive",
}


// export const API_BASE_URL="http://localhost:5000"

export const API_BASE_URL="https://system.anvilstrengthco.com"

