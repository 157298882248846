import React,{useState,useEffect,useContext} from 'react'
import axios from 'axios'
import {API_BASE_URL} from '../utils/Constants'
import {UserContext} from '../utils/UserContext'



const Archive = () => {
    const {username,userToken}=useContext(UserContext)

    const [orders, setOrders] = useState([])

    useEffect(()=>{
        let archive_orders_url=API_BASE_URL+"/orders/archive?username="+username()+"&token="+userToken();
        
        axios.get(archive_orders_url)
        .then(res=>{
            setOrders(res.data["orders"])
        })
        .catch(err=>{console.log(err)})
    },[username,userToken]);


    const setOrderStatus=(id_,status)=>{
        let order_change_url=API_BASE_URL+"/order/change_status/"
        const order=orders.filter((o,id)=>o.order_id===id_)[0]
        if(order.status===status)return;
        order.status=status;
        

        const target_url=order_change_url+order.order_id
        
        const params = new URLSearchParams();
        params.append('status', status);
        params.append('username',username())
        params.append('token',userToken())

       
        axios.post(target_url,params)
        .then(res=>{
            if(res.data.status==="success"){
                setOrders(orders.filter((o,id)=>o.order_id!==id_))
            }
        }).catch(err=>{console.log(err)})


    }


    return (
        <div>

            <table className="archive__table">
                <tr>
                    <th>Order ID</th>
                    <th>Order No.</th>
                    <th>Email</th>
                    <th>Name</th>
                    <th>Date</th>
                    <th>Action</th>
                </tr>

                {
                    orders.map(order=>{
                        return(
                             
                            <tr>
                                <td><a target="_blank" rel="noreferrer" href={"https://undr-australia.myshopify.com/admin/orders/"+order.order_id+"?orderListBeta=true"}>{order.order_id}</a></td>
                                <td><b>{order.order_number}</b></td>
                                <td>{order.customer_email}</td>
                                <td>{order.customer_name}</td>
                                <td>{order.date_created}</td>
                                <td><button className="restore-btn" onClick={(e)=>{e.preventDefault();setOrderStatus(order.order_id,"new_order")}} >Restore</button></td>
                            </tr>
                        )
                    })
                }
            </table>
        </div>
    )
}

export default Archive
