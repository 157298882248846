import React,{useState,useEffect, createContext,useContext} from 'react'
import axios from 'axios'
import {OrderStatus} from '../utils/Constants'

import {DndProvider} from 'react-dnd'
import {HTML5Backend as Backend} from 'react-dnd-html5-backend'
import Board from '../components/Board'
import { UserContext } from '../utils/UserContext'
import {API_BASE_URL} from '../utils/Constants'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export const BoardContext=createContext({
    setOrderStatus:null,
})

const Home = () => {
    const {username,userToken}=useContext(UserContext)
    const [orders, setOrders] = useState([])
    const [mOrders,setmOrders]=useState([])


    useEffect(()=>{
        let order_url=API_BASE_URL+"/list-orders?username="+username()+"&token="+userToken();
        
        axios.get(order_url)
        .then(res=>{
            setOrders(res.data["orders"])
            setmOrders(res.data["orders"])
        })
        .catch(err=>{console.log(err)})
    },[username,userToken]);


    const setOrderSelected=(id_,selected)=>{
        const order=orders.filter((o,id)=>o.order_id===id_)[0]
        order.selected=selected;
        setOrders(orders.filter((o,id)=>o.order_id!==id_).concat(order))
    }


    const setOrderHighlighted=(id_,highlighted)=>{
        const order=orders.filter((o,id)=>o.order_id===id_)[0]
        order.highlighted=highlighted;
        setOrders(orders.filter((o,id)=>o.order_id!==id_).concat(order))
    }


    const updateOrderNote=(order_id,note)=>{

        if(note){
            console.log(order_id,note)
        }else{
            // delete note
            note=""
            console.log(order_id,note)

        }


        let order_change_url=API_BASE_URL+"/order/update_note/"
        const order=orders.filter(order=>order.order_id===order_id)[0]
        order.note=note; 
        setOrders(orders.filter((o,id)=>o.order_id!==order.order_id).concat(order))
        
        const target_url=order_change_url+order.order_id
        
        const params = new URLSearchParams();
        params.append('note', note);
        params.append('username',username());
        params.append('token',userToken())
        
        
        axios.post(target_url,params)
        .then(res=>{
            if(res.data['status']==='success'){
                toast.info('Order Note updated successfully.', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    });
            }else{

                toast.error('Order Note could not be updated.', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    });

            }
        }).catch(err=>{
            // console.log(err)
            toast.error(err, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
        })
        
    }

    const updateOrder=(order,status)=>{

        let order_change_url=API_BASE_URL+"/order/change_status/"
        order.status=status;
        order.selected=false;
        setOrders(orders.filter((o,id)=>o.order_id!==order.order_id).concat(order))
        
        const target_url=order_change_url+order.order_id
        
        const params = new URLSearchParams();
        params.append('status', status);
        params.append('username',username());
        params.append('token',userToken())
        params.append('take_no_action',order.highlighted?'true':'false')

        
        axios.post(target_url,params)
        .then(res=>{
            if(res.data['status']==='success'){
                toast.info('Order status updated successfully.', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    });
            }else{

                toast.error('Order status could not be updated.', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    });

            }
        }).catch(err=>{
            // console.log(err)
            toast.error(err, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
        })
        
        
    }

    const setOrderStatus=(id_,status)=>{

        const order=orders.filter((o,id)=>o.order_id===id_)[0]
        if(order.status===status)return;

        if(order.selected===true){
            const selectedOrders=orders.filter((o,id)=>o.status===order.status && o.selected===true)
            selectedOrders.forEach((order_,id)=>{
                updateOrder(order_,status)
            })
        }else{

            updateOrder(order,status)

        }

    }





    const searchOrders=(e)=>{
        var text=e.target.value.trim().toLowerCase();
        if(text===""){
            setmOrders(orders)
        }else{
            
            setmOrders(orders.filter(order=>{
                return order.customer_name.toLowerCase().indexOf(text)!==-1 || String(order.order_number).startsWith(text)
            }))
            

        }
    }



    return (
        <div>

            <div>
                <form  className="flex-container centered" onSubmit={(e)=>{e.preventDefault()}}>
                    <label htmlFor="search_term">Search&nbsp;</label>
                    <input type="text" className="search_box" name="search_term" placeholder="Enter order no. or name" id="searchterm" onChange={(e)=>searchOrders(e)} />
                </form>
                <br />
            </div>
            <ToastContainer />
            <BoardContext.Provider value={{setOrderStatus,setOrderSelected,setOrderHighlighted,updateOrderNote}}>
                <DndProvider backend={Backend}>
                <div className="grid">
                    <Board title="New Orders" orders={mOrders}  status={OrderStatus.ORDER_STATUS_NEW_ORDER} />
                    <Board title="Custom Work" orders={mOrders}  status={OrderStatus.ORDER_STATUS_CUSTOM_WORK} />
                    <Board title="Received & Processed" orders={mOrders}  status={OrderStatus.ORDER_STATUS_RECEIVED_AND_PROCESSED} />
                    <Board title="In Production" orders={mOrders}  status={OrderStatus.ORDER_STATUS_IN_PRODUCTION}/>
                    <Board title="Almost Complete" orders={mOrders}  status={OrderStatus.ORDER_STATUS_ALMOST_COMPLETE}/>
                    <Board title="Final Stages" orders={mOrders}  status={OrderStatus.ORDER_STATUS_FINAL_STAGES}/>
                    <Board title="Complete" orders={mOrders}  status={OrderStatus.ORDER_STATUS_COMPLETE}/>
                    <Board title="Archive" orders={mOrders}  status={OrderStatus.ORDER_STATUS_ARCHIVE}/>
                </div>
                </DndProvider>
            </BoardContext.Provider>
           
        </div>
    )
}

export default Home
